<template>
  <section id="banner" class="page align-middle">
    <banner-image-overlay :fullscreen="true"></banner-image-overlay>
    <div class="main-container">
      <h2 class="title">Login</h2>
      <div class="d-flex justify-content-center">
        <article>
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <input name="email" v-model="email"
                    class="form-control" placeholder="Email"
                    type="email" required maxlength="64"
                    autocomplete="username">
            </div>
            <div class="form-group">
              <input name="password" v-model="password"
                    class="form-control" placeholder="Password"
                    type="password" required minlength="8" maxlength="20"
                    autocomplete="current-password">
            </div>
            <div class="alert alert-success align-left" v-show="success">
              {{ success }}
            </div>
            <div class="alert alert-danger align-left" v-show="error">
              <div v-html="error"></div>
              <div v-show="userNotActiveError">
                <a @click.stop.prevent="resendActivationEmail">Resend activation email?</a>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <button type="submit" class="button special">Login</button>
                </div>
              </div>
              <div class="col-6 text-right">
                <router-link :to="{ name: 'ResetPassword' }"
                class="small reset-password">Forgot password?</router-link>
              </div>
            </div>
          </form>
          <!-- <hr> -->
          <!-- <div class="social-media">
            <div class="row">
              <div class="col">
                <h3 class="title">Or Via Social Media</h3>
              </div>
            </div>
            <div class="row buttons">
              <div class="col-lg">
                <a href="" class="btn btn-block btn-outline-primary btn-linkedin">
                  <i class="fab fa-linkedin"></i> &nbsp;  LinkedIn
                </a>
              </div>
              <div class="col-lg">
                <a href="" class="btn btn-block btn-outline-primary btn-twitter">
                  <i class="fab fa-twitter"></i> &nbsp;  Twitter
                </a>
              </div>
              <div class="col-lg">
                <a href="" class="btn btn-block btn-outline-primary btn-facebook">
                  <i class="fab fa-facebook-f"></i> &nbsp;  Facebook
                </a>
              </div>
            </div>
          </div> -->
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';
import UserApi from '../../api/User';
import types from '../../store/types';

export default {
  name: 'Signin',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      email: null,
      password: null,
      error: '',
      success: '',
      userNotActiveError: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
  methods: {
    ...mapActions({
      saveUser: types.actions.user.SAVE,
    }),
    resendActivationEmail() {
      this.error = '';
      this.success = '';
      this.userNotActiveError = false;
      UserApi
        .resendActivationEmail(this.email)
        .then(({ data }) => {
          this.success = data.message;
        })
        .catch((error) => {
          const contactPage = this.$router.resolve({name: 'Contact'});
          this.error = this.parseError(error, 'Unable to resend the email for activating your account. ' +
            `Please try again, if this error persists <a href="${contactPage}" target="_blank">contact us</a>`)
        });
    },
    onSubmit() {
      this.error = '';
      this.success = '';
      this.userNotActiveError = false;
      const self = this;
      UserApi
        .signin(this.email, this.password)
        .then(({ data }) => {
          this.saveUser(data);
          self.$router.push({ name: 'Dashboard' });
          window.fbq('track', 'CompleteRegistration', {
            content_name: 'signin',
          });
        })
        .catch((error) => {
          // User needs to activate account
          if (error.response.status === 470) {
            this.userNotActiveError = true;
          }
          this.error = this.parseError(error, 'Unable to login, please try later');
        });
    },
  },
};
</script>

<style scoped>
  .social-media .title {
    color: white;
  }

  .reset-password {
    position: relative;
    top: -8px;
    color: white;
    font-style: italic;
  }

  .reset-password:hover {
    color: #f32853;
  }

  strong, b {
    color: #B6B6B6;
  }

  article {
    width: 500px;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: #38AAF0;
  }

  .alert a {
    text-decoration: underline;
    cursor: pointer;
  }

  button[type=submit] {
    float: left;
  }

  i.fab {
    margin-right: 7px;
  }

  .btn-outline-primary {
    font-size: 17px;
  }

  .btn-outline-primary.btn-linkedin {
    border-color: #0077B5 !important;
    color: white;
    background-color: #0077B5 !important;
  }

  .btn-outline-primary.btn-twitter {
    border-color: #1DA1F2 !important;
    color: white;
    background-color: #1DA1F2 !important;
  }

  .btn-outline-primary.btn-facebook {
    border-color: #4267B2 !important;
    color: white;
    background-color: #4267B2 !important;
  }

  .fa-facebook-f:before {
    content: "\f39e";
  }

  #banner {
    height: 100%;
    padding-top: 24vh;
  }

  @media screen and (max-width: 991px) {
    .social-media .buttons .col-lg .btn-outline-primary {
      margin-top: 15px;
    }

    .social-media .buttons .col-lg:first-child .btn-outline-primary {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 736px) {
    #banner {
      padding-left: 0;
      padding-right: 0;
    }

    .main-container {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media screen and (max-height: 567px) {
    #banner {
      height: fit-content;
    }
  }

  @media screen and (max-width: 480px) {
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        padding: 0 2.2em;
    }
  }
</style>
